import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "./ConnectionForm.scss";
import backArrowIcon from "assets/images/back-arrow.svg";
import {
  addConnection,
  changeStatusConnection,
  deleteConnection,
  editConnection,
  getConnection,
  getServices,
} from "services/api/connection";
import toastify from "shared/toastr";
import LoaderInline from "shared/loader/LoaderInline";
import LoaderFull from "shared/loader/LoaderFull";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import Datetime from "react-datetime";
import moment from "moment";

const ConnectionForm = () => {
  // kitchenId for adding new connection, connId for get and edit
  const [services, setServices] = useState([]);
  const { restId, kitchenId, connId } = useParams();
  const history = useHistory();
  const [isLoading, setisLoading] = useState(false);
  const [connection, setConnection] = useState(null);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [dateTime, setDateTime] = useState('');
  const [status, setStatus] = useState('true');

  useEffect(() => {
    getDeliveryServices();
  }, []);

  useEffect(() => {
    if (connId) {
      if (parseInt(connId) > 0) {
        setisEditMode(true);
        getDetail(+connId);
      }
    }
  }, [connId]);

  const validationSchema = Yup.object().shape({
    /* username: Yup.string()
      .trim()
      .max(100, ({ max }) => `Username name not more than ${max} characters`)
      .required("Please enter user name"),
    password: Yup.string()
      .trim()
      .max(250, ({ max }) => `Password not more than ${max} characters`)
      .required("Please enter the password"), */
    url: Yup.string()
      .nullable()
      .trim()
      .url("Please enter a valid URL")
      .max(500),
    serviceId: Yup.number()
      .test("isSelected", "Please select marketplace", (value) => +value !== 0)
      .required("Please select marketplace name"),
    // marketplaceId: Yup.string()
    //   .max(250, ({ max }) => `Marketplace ID not more than ${max} characters`)
    //   .required("Please enter marketplace id"),
    status: Yup.string(),
    // platformPickupRate: Yup.number()
    //   .typeError("Only digits allowed")
    //   .positive()
    //   .min(0, () => `Platform delivery rates must be greater than or equal to 0`)
    //   .max(100, () => `Platform delivery rates must be less than or equal to 100`),
      //.required("Please enter platform pickup rates"),
    // platformDeliveryRate: Yup.number()
    //   .typeError("Only digits allowed")
    //   .positive()
    //   .min(0, () => `Platform delivery rates must be greater than or equal to 0`)
    //   .max(100, () => `Platform delivery rates must be less than or equal to 100`),
      //.required("Please enter platform delivery rates"),
    reason: Yup.string().nullable()
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const getDeliveryServices = () => {
    getServices().then((res) => {
      let data = res.data;
      if (data && data?.length) {
        let filtered = data.map((res) => ({ label: res.name, value: res.id }));
        setServices(filtered);
      }
    });
  };

  const getDetail = async (id) => {
    setisLoading(true);
    await getConnection(id)
      .then((res) => {
        let data = res.data;
        reset({
          url: data?.url,
          username: data?.userName,
          password: data?.password,
          serviceId: "" + data?.platformMasterId,
          marketplaceId: "" + data?.marketPlaceId,
          platformPickupRate: data?.platformPickupRate,
          platformDeliveryRate: data?.platformDeliveryRate,
          status: "" + data?.isActive,
          dateTime: data?.pausedUntil,
          reason: data?.reason,
        });
        setConnection(data);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
        onBack();
      });
  };

  const onBack = () => {
    history.goBack();
  };

  const onDelete = async () => {
    setisLoading(true);
    await deleteConnection(connId)
      .then((res) => {
        toastify("success", res.message);
        setisLoading(false);
        onBack();
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const onChangeStatus = async () => {
    setisLoading(true);
    await changeStatusConnection(connId, !connection?.isActive)
      .then((res) => {
        toastify("success", res.message);
        setisLoading(false);
        onBack();
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const onSubmit = async (data) => {
    setisSubmitting(true);
    const isValid = status === 'false' && !dateTime ? false : true;
    if (isValid) {
      data["pausedUntil"] = dateTime ? dateTime.utc().format('YYYY-MM-DDTHH:mm:ssZ') : null;
      if (isEditMode) {
        await editConnection(+connId, data, +kitchenId)
          .then((res) => {
            toastify("success", res.message);
            setisSubmitting(false);
            onBack();
          })
          .catch(() => {
            setisSubmitting(false);
          });
      } else {
        await addConnection(+kitchenId, data)
          .then((res) => {
            toastify("success", res.message);
            setisSubmitting(false);
            onBack();
          })
          .catch(() => {
            setisSubmitting(false);
          });
      }
    }
  };

  const validStart = (current) => {
    const yesterday = moment().subtract(1, "day");
    const maxEndDate = moment().add(3, "days"); 
    const range = current.isBefore(maxEndDate);
    return current.isAfter(yesterday) && range; 
  };

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <button type="button" onClick={onBack} className=" back-btn-red">
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Back
          </button>
        </div>
      </Subheader>
      {isLoading ? <LoaderFull /> : null}
      <div
        className={
          isEditMode ? "edit__connection__wrapper" : "AddNewConnection__wrapper"
        }
      >
        <div
          className={`modifierDetails__wrapper ${
            isEditMode ? "" : "modifierEdit__wrapper"
          }`}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="goBack">
                  <button
                    type="button"
                    onClick={onBack}
                    className=" back-btn-red"
                  >
                    <span className="icons">
                      <img src={backArrowIcon} alt="back icon" />
                    </span>
                    Back
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="main_heading">
                  <h1>{isEditMode ? "Edit" : "Add New"} Connection</h1>
                </div>
              </div>
              <div className="col-12">
                <div
                  className={`details__wrapper ${
                    isEditMode ? "" : "editModifier__wrapper"
                  }`}
                >
                  <div className="details__wrapper__inner">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="basic__info__content">
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                          <div
                            className={`${
                              isEditMode ? "left_content" : "w-100"
                            }`}
                          >
                            {/* <div className="form-row">
                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="username">
                                  Username
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="username"
                                  placeholder="e.g Nombeko Mabandla"
                                  maxLength="100"
                                  {...register("username")}
                                  className={`form-control ${
                                    errors.username ? "is-invalid" : ""
                                  }`}
                                />
                                {errors.username?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.username?.message}
                                  </div>
                                )}
                              </div>

                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="password">
                                  Password
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="password"
                                  placeholder="Enter password"
                                  maxLength="250"
                                  {...register("password")}
                                  className={`form-control ${
                                    errors.password ? "is-invalid" : ""
                                  }`}
                                />
                                {errors.password?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.password?.message}
                                  </div>
                                )}
                              </div> */}

                            <div className="form-row">
                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="url">
                                  Url
                                  {/* <span className="mendatory">*</span> */}
                                </label>
                                <input
                                  type="text"
                                  id="url"
                                  placeholder="Enter Platform URL"
                                  maxLength="100"
                                  {...register("url")}
                                  className={`form-control ${
                                    errors.url ? "is-invalid" : ""
                                  }`}
                                />
                                {errors.url?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.url?.message}
                                  </div>
                                )}
                              </div>

                              <div class="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="mandatory">
                                  Marketplace Name
                                  <span className="mendatory">*</span>
                                </label>
                                {services && services?.length && (
                                  <>
                                    <select
                                      {...register("serviceId")}
                                      className={`form-control big-select ${
                                        errors.serviceId ? "is-invalid" : ""
                                      }`}
                                    >
                                      <option value={0}>
                                        No Marketplace Selected
                                      </option>
                                      {services &&
                                        services?.map((res, idx) => (
                                          <option key={idx} value={res.value}>
                                            {res.label}
                                          </option>
                                        ))}
                                    </select>
                                    {errors.serviceId?.message && (
                                      <div className="invalid-feedback d-block">
                                        {errors.serviceId?.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>

                              {/* <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="marketPlaceId">
                                  Marketplace ID
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="marketPlaceId"
                                  placeholder="E.g. 745210"
                                  {...register("marketplaceId")}
                                  className={`form-control ${
                                    errors.marketplaceId ? "is-invalid" : ""
                                  }`}
                                />
                                {errors.marketplaceId?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.marketplaceId?.message}
                                  </div>
                                )}
                              </div> */}

                              {/* <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="platformPickupRate">
                                  Platform Pickup Rates
                                  <span className="mendatory">*</span>
                                </label>
                                <input
                                  type="text"
                                  id="platformPickupRate"
                                  placeholder="E.g. 745210"
                                  {...register("platformPickupRate")}
                                  className={`form-control ${
                                    errors.platformPickupRate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                {errors.platformPickupRate?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.platformPickupRate?.message}
                                  </div>
                                )}
                              </div>

                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="platformDeliveryRate">
                                  Platform Delivery Rates
                                  <span className="mendatory">*</span> 
                                </label>
                                <input
                                  type="text"
                                  id="platformDeliveryRate"
                                  placeholder="E.g. 745210"
                                  {...register("platformDeliveryRate")}
                                  className={`form-control ${
                                    errors.platformDeliveryRate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                {errors.platformDeliveryRate?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors.platformDeliveryRate?.message}
                                  </div>
                                )}
                              </div> */}

                              {!isEditMode ? (
                                <div class="col-md-12 mb-3 pb-3 px-0">
                                  <label htmlFor="mandatory">
                                    Marketplace Status
                                  </label>
                                  <select
                                    className={`form-control big-select ${
                                      errors?.status ? "is-invalid" : ""
                                    }`}
                                    {...register("status")}
                                    onChange={(evt) =>
                                      setStatus(evt.target.value)
                                    }
                                  >
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                                  </select>
                                </div>
                              ) : null}

                              {status === "false" && (
                                <div
                                  className="col-md-12 mb-3 pb-3 px-0"
                                  style={{ display: "flex" }}
                                >
                                  <div className="col-md-6 px-0">
                                    <label htmlFor="from">Paused Until</label>
                                    <Datetime
                                      inputProps={{
                                        placeholder: "MM/DD/YYYY HH:mm",
                                      }}
                                      isValidDate={validStart}
                                      onChange={(date) => setDateTime(date)}
                                      timeFormat="HH:mm:ss"
                                      timeConstraints={{
                                        hours: { min: 0, max: 23 },
                                      }}
                                    />
                                    {status === "false" && !dateTime && (
                                      <div className="invalid-feedback d-block">
                                        Please select date time
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              <div className="col-md-12 mb-3 pb-3 px-0">
                                <label htmlFor="reason"> Reason</label>
                                <textarea
                                  id="reason"
                                  placeholder="Provide reason"
                                  style={{ minHeight: "90px" }}
                                  className={`form-control ${
                                    errors.name ? "is-invalid" : ""
                                  }`}
                                  {...register("reason")}
                                  maxLength="500"
                                  rows="3"
                                ></textarea>
                                {errors?.reason?.message && (
                                  <div className="invalid-feedback d-block">
                                    {errors?.reason?.message}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {isEditMode ? (
                            <div className="right_content d-none d-md-block">
                              <span
                                className={`custom__badge ${
                                  connection?.isActive
                                    ? "badge__red"
                                    : "badge__gray"
                                } table__badge`}
                              >
                                {connection?.isActive ? "Active" : "Inactive"}
                              </span>
                              <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                                <button
                                  type="button"
                                  className="btn btn-secondary dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  id="dropdown-button-drop-left"
                                ></button>
                                <div className="dropdown-menu">
                                  <div className="dropdown__innerWrapper">
                                    <button
                                      type="button"
                                      className="dropdown-item"
                                      onClick={onChangeStatus}
                                    >
                                      <span>
                                        Set as
                                        {!connection?.isActive
                                          ? " Active"
                                          : " Inactive"}
                                      </span>
                                    </button>
                                    <button
                                      type="button"
                                      className="dropdown-item"
                                      onClick={onDelete}
                                    >
                                      <span>Delete</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-12 px-0">
                          <div className="modal-footer">
                            <button
                              onClick={onBack}
                              type="button"
                              className="cancel-btn red-btn-ghost btn"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="red-btn next-btn btn"
                              disabled={
                                isSubmitting ||
                                !isValid ||
                                (status === "false" && !dateTime)
                              }
                            >
                              {isSubmitting ? (
                                <LoaderInline />
                              ) : isEditMode ? (
                                "Save"
                              ) : (
                                "Save"
                              )}
                            </button>
                          </div>
                        </div>
                        {isEditMode ? (
                          <div className="right_content d-md-none mt-0">
                            <span
                              className={`custom__badge ${
                                connection?.isActive
                                  ? "badge__red"
                                  : "badge__gray"
                              } table__badge`}
                            >
                              {connection?.isActive ? "Active" : "Inactive"}
                            </span>
                            <div className="btn-group dropleft threeDotsBropdown drop-icon ml-2">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="dropdown-button-drop-left"
                              ></button>
                              <div className="dropdown-menu">
                                <div className="dropdown__innerWrapper">
                                  <button
                                    type="button"
                                    className="dropdown-item"
                                    onClick={onChangeStatus}
                                  >
                                    <span>
                                      Set as
                                      {!connection?.isActive
                                        ? " Active"
                                        : " Inactive"}
                                    </span>
                                  </button>
                                  <button
                                    type="button"
                                    className="dropdown-item"
                                    onClick={onDelete}
                                  >
                                    <span>Delete</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectionForm;
